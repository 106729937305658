<template lang="">
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <div :class="accCards.length>0?'d-flex flex-column-reverse':''">

      <b-card>
        <b-row
          align-v="center"
          align-h="between"
        >
          <b-col cols="auto">
            <div class="my-1">
              <b-img
                v-if="metatraderVersions.includes('mt4')"
                fluid
                width="100"
                style="margin-right: 3px;"
                :src="require('@/assets/images/forex/account-meta4.png')"
              />
              <b-img
                v-if="metatraderVersions.includes('mt5')"
                fluid
                width="100"
                style="margin-right: 3px;"
                :src="require('@/assets/images/forex/account-meta5.png')"
              />
            </div>
            <p>
              {{ $t('forex.create-account-btn-text') }}
            </p>
          </b-col>
          <b-col
            cols="auto"
            class="mt-2 mt-md-0"
          >
            <b-button
              class="mt-1"
              variant="primary"
              :to="{name:'forexModule-metatrader-create-account'}"
            >
              {{ $t('forex.create-account-btn') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card v-if="accCards.length>0">
        <b-row>

          <b-col>
            <b-card no-body>

              <div class="d-flex justify-content-end">

                <div class="d-flex">
                  <div
                    class="my-1 mx-1"
                  >
                    <p
                      style="font-size:.85rem!important"
                      class="text-muted"
                    >
                      {{ $t('update-wallets') }}   </p>
                  </div>
                  <div>
                    <b-button
                      pill
                      variant="primary"
                      class="px-1 py-1 shadow bg-white"
                      @click="refresh"
                    >
                      <feather-icon
                        :class="refreshIcon?'refreshAnimate':''"
                        icon="RefreshCwIcon"
                        class="cursor-pointer"
                        size="18"
                      /></b-button>
                  </div>
                </div>
              </div>
              <b-row>
                <b-col
                  v-for="item in accCards"
                  :key="item.id"
                  cols="12"
                  md="6"
                  xl="4"
                >
                  <MetaAccountCard
                    :acc="item"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BButton,
  BImg,
} from 'bootstrap-vue'
import MetaAccountCard from '@/modules/forex/views/metatrader/MetaAccountCard.vue'
import MetatraderService from '@/modules/forex/service/apis'

const accountCards = new MetatraderService()

export default {
  components: {
    BOverlay,
    BImg,
    BCard,
    BRow,
    BCol,
    BButton,
    MetaAccountCard,
  },
  data() {
    return {
      accCards: [],
      show: false,
      refreshIcon: false,
    }
  },
  computed: {
    metatraderVersions() {
      return process.env.VUE_APP_PLATFORMS.split(',')
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.show = true
      await accountCards.getAccounts().then(res => {
        this.accCards = res.data.results
        this.show = false
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.show = false
        this.refreshIcon = false
      })
    },
    refresh() {
      this.refreshIcon = true
      this.getData()
    },

  },

}
</script>
<style>
.refreshAnimate {
  animation: rotate 1.5s linear infinite !important;
}
</style>
