<template>
  <b-row
    class="mt-1"
  >
    <b-col>
      <b-card
        class="rounded-lg"
        style="box-shadow: 0px 0px 15px -5px rgba(150, 150, 150, 0.6) !important; "
      >
        <div class="position-relative">
          <div class="text-center">
            <h3>
              {{ acc.account }}
            </h3>
          </div>
          <div
            class="position-absolute remove-p"
            style="right:0; top:0;"
          >
            <b-dropdown
              right
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="25"
                  class="text-body p-0"
                />
              </template>

              <b-dropdown-item :to="{name:'forexModule-trade-History',params:{platform:acc.platform,id:acc.account}}">
                <feather-icon
                  icon="CornerUpLeftIcon"
                  class="mr-50"
                />
                <span>{{ $t('forex.trade-history') }}</span>
              </b-dropdown-item>
              <b-dropdown-item :to="{name:'forexModule-DW-History',params:{platform:acc.platform,id:acc.account}}/*`transfer-history/${acc.platform}/${acc.account}`*/">
                <feather-icon
                  icon="CornerUpLeftIcon"
                  class="mr-50"
                />
                <span>{{ $t('forex.transfer-history') }}</span>
              </b-dropdown-item>
              <b-dropdown-item :to="{name:'forexModule-transfer-account-balance',params:{platform:acc.platform,id:acc.account}}">
                <feather-icon
                  icon="RepeatIcon"
                  class="mr-50"
                />
                <span>{{ $t('forex.internal-transfer') }}</span>
              </b-dropdown-item>
              <b-dropdown-item :to="{name:'forexModuleaccount-change-password',params:{platform:acc.platform,id:acc.account}}">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>{{ $t('forex.change-password') }}</span>
              </b-dropdown-item>

            </b-dropdown>
          </div>
        </div>
        <hr>
        <div
          class="mx-1"
          style="direction: ltr;"
        >
          <b-row>
            <b-col>
              <div
                :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-white' : ''"
                class="text-center"
              >
                <h4>{{ acc.group }}</h4>
                <span class="text-muted">{{ $t('forex.group') }}</span>

              </div>
            </b-col>
            <b-col>
              <div
                :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-white' : ''"
                class="text-center"
              >
                <h4>{{ acc.platform.toUpperCase() }}</h4>
                <span class="text-muted">{{ $t('forex.platform') }}</span>
              </div>
            </b-col>
          </b-row>
        </div>

        <hr>
        <div
          class="text-center"
          dir="ltr"
        >
          <h4>{{ acc.balance }} USD</h4>
          <span class="text-muted">{{ $t('forex.balance') }}</span>
        </div>
        <hr>
        <div
          class="d-flex justify-content-between text-center mx-1"
          style="direction: ltr;"
        >
          <div dir="ltr">
            <h4 style="font-size: 1.10rem;">
              {{ acc.equity }} USD
            </h4>
            <span class="text-muted">{{ $t('forex.equity') }}</span>
          </div>
          <div dir="ltr">
            <h4 style="font-size: 1.10rem;">
              {{ acc.margin }} USD
            </h4>
            <span class="text-muted">{{ $t('forex.margin') }}</span>
          </div>
        </div>
        <hr>
        <b-row
          class="d-flex mt-2 justify-content-between"
          style="direction: ltr;"
        >

          <b-col cols="6">
            <b-button
              class="d-flex justify-content-center align-items-center m-auto customHover w-100"
              variant="success"
              :to="{name:'forexModule-deposit',params:{id:acc.account}}"
            >
              <span>{{ $t('crypto_wallet_deposit') }}</span>
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button
              class="d-flex justify-content-center align-items-center m-auto customHover w-100"
              variant="danger"
              :to="{name:'forexModule-withdrawal',params:{id:acc.account}}"
            >
              <span> {{ $t('crypto_wallet_withdrawal') }} </span>
            </b-button>
          </b-col>

        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
  },
  props: {
    acc: {
      required: true,
      type: Object,
    },
  },
}
</script>
<style scoped>

.customHover {
  transition: all .2s;
  backface-visibility: hidden;
}
.remove-p::v-deep  .btn{
  padding:0 !important;
}
</style>
